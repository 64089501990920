import React from "react";
import classnames from "classnames";

import { Popup } from "./components/Popup";

import "./App.css";

const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

const App = () => {
    const [coords, setCoords] = React.useState({ latitude: 0, longitude: 0 });
    const [showPopup, setShowPopup] = React.useState(
        isIos() && !isInStandaloneMode()
    );

    return (
        <div className={classnames(showPopup && "modal-popup-opened")}>
            <button
                onClick={() => {
                    navigator.geolocation.getCurrentPosition(
                        position => {
                            const { latitude, longitude } = position.coords;
                            setCoords({
                                latitude,
                                longitude,
                            });
                        },
                        err => console.log("err: ", err)
                    );
                }}
            >
                Request geolocation
            </button>
            <br />
            <br />
            <div>latitude: {coords.latitude}</div>
            <div>longitude: {coords.longitude}</div>

            <br />
            <br />
            <br />

            {showPopup && <Popup onClose={() => setShowPopup(false)} />}
        </div>
    );
};

export default App;
